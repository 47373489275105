import request from 'superagent'

const API_URL = 'https://payment.tome.ge/api'

const API_ENDPOINTS = {
  getPayment: id => `${API_URL}/payments/${id}`,
  sendPayment: id => `${API_URL}/payments/${id}/confirm`,
}

export default class Api {
  static getPayment(values, queryParams) {
    const { id } = values
    const endpoint = API_ENDPOINTS.getPayment(id)

    return request
      .get(endpoint)
      .query(queryParams)
      .accept('application/json')
  }

  static sendPayment(values) {
    const { id, data } = values
    const endpoint = API_ENDPOINTS.sendPayment(id)

    return request
      .post(endpoint)
      .send({ customer: data })
      .accept('application/json')
  }
}
